<template>
    <div class="page">
        <div class="header">
            <div class="logo">
                <img src="@/assets/logo.jpeg" alt="分时预约系统">
            </div>
            <div class="clock">
                <div class="date">
                    {{date}}
                </div>
                <div class="time">
                    {{time}}
                </div>
            </div>
        </div>
        <div class="main">

            <div class="room">
                {{data.room.name}} （{{data.room.department.name}}）
            </div>
            <div class="now">
                <div class="box">
                    <div style="padding-top:30px;font-size: 600%">
                    正在就诊
                    </div>
                    <h3>{{data.now.patient}}</h3>
                </div>
            </div>
            <div class="wait">
                <div class="box">
                    <div class="wait_title">
                    等候就诊
                    </div>
                    <div class="name" v-for="(item, idx) in data.next" :key="idx">{{item.patient}}</div>
                </div>
            </div>
            <div class="doctor">
                <div class="img">
                    <img :src="data.room.doctor.avatar" height="100%"/></div>
                <div class="text">
                    <h3>{{data.room.doctor.name}}
                        <div class="desc">{{data.room.doctor.title}}</div></h3>

                    <div class="content">{{data.room.doctor.summary}}</div>
                </div>
            </div>

        </div>
        <div class="footer">请其他患者按顺序排队等候!</div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                date: '',
                time: '',
                data: {room:{doctor: {}, department:{}}, now:{}, next:[]}
            }
        },
        methods: {
            now() {
                setInterval(() => {
                    let n = new Date();
                    this.date = n.format("yyyy年MM月dd日")
                    this.time = n.format("hh:mm:ss")
                    this.getData()
                }, 1000)
            },
            async getData() {
                let room_id = this.$route.query.id
                const resp = await this.$http.get(`/mingde/room/${room_id}`)
                console.log(resp.data.data)
                this.data = resp.data.data
            }
        },
        mounted() {
            this.now()
            this.getData()
        }
    }
</script>

<style lang="scss" scoped>
    .page {
        height: 100%;
    }

    .header {
        display: flex;
        padding: 30px 20px;
        height:auto;
    }

    .logo {
        flex: 1;
        img{
            height:94px;
        }
    }

    .clock {
        text-align: right;
    }
    .date{
        font-size:36px;
    }
    .time{
        font-size:36px;
        margin-top:5px;
    }
    .main {
        background: #4f9fe7;
        padding: 10px;
        padding-bottom:0;
        position: absolute;
        top: 150px;
        left: 0;
        right: 0;
        bottom: 80px;
    }
    .box {
        background: #acc9e7;
        height: 100%;
        margin: 20px 10px;
        text-align: center;
    }

    .room {
        background: #ffffff;
        text-align: center;
        font-size: 350%;
        border-radius: 120px;
        margin: 10px;
        padding:20px;
    }

    .now {
        height: 35%;
        h3 {
            margin:0;
            padding: 0;
            margin-top:30px;
            font-size: 1200%;
        }
    }

    .wait {
        height: 20%;
        .wait_title{
            font-size:400%;
            padding-top:40px;
            padding-bottom:40px;
            font-weight:bold;
        }
        .name {
            font-size: 400%;
            text-align:left;
            padding-left:40%;
            line-height:150%;
        }
        .name b{
            font-weight:normal;
            display:inline-block;
            padding-right:5%;
        }
    }

    .doctor {
        height: calc(35% - 10px);
        display: flex;
        margin-top:10px;
        .img {
            margin: 10px;
            height:calc(100% - 20px);
        }
        .text {
            height:calc(100% - 20px);
            background: #acc9e7;
            margin: 10px;
            flex: 1;
            padding: 0 20px;
            h3{
                font-size:40px;
                margin:20px 0;
                .desc{
                    font-size:30px;
                    display:inline-block;
                    margin-left:20px;
                    font-weight:normal;
                }
            }

            .content{
                font-size:24px;
                line-height:50px;
                text-indent:2em;
                text-align: justify;
            }
        }
    }

    .footer {
        text-align: center;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        line-height: 80px;
        font-size:32px;
        height:80px;
    }
</style>
